import { Icon } from '@iconify/react/dist/iconify.js'
import React, { useEffect, useState } from 'react'
import Renderclasses from './Renderclasses'
import Backend_Url from '../Config/BackendUrl'
import token from '../Config/Token'
import axios from 'axios'

const Neet12th = () => {
    const [test, settest] = useState(null)
    const fetchdata = async () => {
        try {
            await axios.get(`${Backend_Url}/Footer/getAll`,
                {
                    headers: {
                        'authorization': 'Bearer ' + token
                    }
                }
            )
                .then((res) => settest(res.data.data[0].TextContent))
        } catch (error) {
            console.log(error)
            return window.alert('Error while fetching data')
        }
    }

    useEffect(() => {
        fetchdata()
    }, []);
    return (
        <>
            {/* div-for-show-12th */}
            <div className={`main-div-12th`} s>
                {/* <div dangerouslySetInnerHTML={{ __html: test }} /> */}
                <h1>NEET programs for Class 12th</h1>
                <div className={`sub-div`}>
                    <button>
                        <span>
                            <Icon icon="healthicons:i-exam-multiple-choice-outline" style={{ fontSize: '18px', color: "black" }} />
                        </span>
                        <p>Online Test Series</p>
                    </button>
                    <button>
                        <span>
                            <Icon icon="arcticons:fiitjee-recorded-lectures" style={{ fontSize: '18px', color: "black" }} />
                        </span>
                        <p>Ofline Test Series</p>
                    </button>
                </div>
            </div>
            {/* -------------------render all classess----------------------- */}
            <Renderclasses />
        </>
    )
}

export default Neet12th