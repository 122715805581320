import React from 'react'
import Backend_Url from '../../Config/BackendUrl'
import token from '../../Config/Token'
import axios from 'axios'

const RefundPolicy = () => {
    const [data, setdata] = React.useState([])
    const fetchdata = async () => {
        try {
            await axios.get(`${Backend_Url}/Footer/getAll`,
                {
                    headers: {
                        'authorization': 'Bearer ' + token
                    }
                }
            )
                .then((res) => setdata(res.data.data))
        } catch (error) {
            console.log(error)
            return window.alert('Error while fetching data')
        }
    }

    const que = [
        {
            question: 'For which target exam this course is?',
            answer: 'NEET (UG) – Target 2025.'
        },
        {
            question: 'What will be the content deliverables in this Test Series Packages?',
            answer: 'The content deliverables in this Package are:- 1 16 Part test along with AIOOT  conducted at regular intervals as per the latest exam pattern. 2.14 Full syllabus test as per the latest exam pattern. 3Practice Series on SHRI JI App & Website'
        },
        {
            question: 'What are the features in the Practice Series on SHRI JI App?',
            answer: 'Following are the features in Practice Series on SHRI JI App- 1.•	Custom practice: Create a customized practice quiz from a bank of 15000+ Questions. You can choose from any topic, PYQ’s & difficulty level to create your own set of practice questions. 2 •	Online practice problems: Selected list of practice problems from all topics 3•	Digital Improvement Book: keep track of mistakes you have made on SHRI JI App to improve revision speed '
        },
        {
            question: 'Will the entire syllabus of the NEET(UG) Examination be covered?',
            answer: 'Yes, the entire syllabus of class 11th and class 12th as per the latest NEET (UG) pattern will be covered.'
        },
        {
            question: 'Will I get all the tests on the revised syllabus 2025?',
            answer: 'Yes the tests are based on NEET(UG) 2025 revised syllabus as released by NTA (National Testing Agency).'
        },
        {
            question: 'What does the AIOOT type test mean?',
            answer: 'AIOOT is an ALL- India Online Open Test conducted on a schedule date and time. Through it, you will get the opportunity to compete with SHRI JI students at national level and evaluate your performance level accordingly. You can refer to the test schedule and syllabus document for the details.'
        },
        {
            question: 'Where can I find the test syllabus and schedule?',
            answer: 'You can find the test syllabus and schedule on the course detail page. You can also download it for future reference'
        },
        {
            question: 'What will be the mode of the Tests?',
            answer: 'Each test will be conducted in live (Online ) mode. You can attempt the test from your mobile phone, computer, tablet or laptop. It requires uninterrupted internet connection.'
        },
        {
            question: 'What does a 24 hr test window mean?',
            answer: 'It means you can attempt the test at any hour of the day on its scheduled date from 00.00 Am to 11.59 PM. However, student support will only be available from 08.00 Am to 10.00PM.'
        },
        {
            question: 'What language will the test be in?',
            answer: 'Tests will be conducted in English.'
        },
        {
            question: 'Can I reattempt the test once conducted ?',
            answer: 'Yes, once conducted, you can reattempt all the tests in an asynchronous mode (anytime & anywhere) as a practice test for self-evaluation.'
        },
        {
            question: 'Where can I find the test to re-attempt?',
            answer: 'Once you log in to the SHRI JI App or the website, you can find the test in the TEST” tab.'
        },
        {
            question: 'Can I download the test papers after attempting the test?',
            answer: 'No.'
        },
        {
            question: 'Will there be any solutions to the questions provided?',
            answer: 'Yes, the answers, hints, and solutions to all the questions will be provided on the SHRI JI App & website.'
        },
        {
            question: 'Will there be any doubt sessions provided with the test series package?',
            answer: 'No, there will be no doubt session facility with this test series package. But after every test paper discussion facility available, it will be Online, you will get the link after the exam.'
        },
        {
            question: 'When will I get the result of my test?',
            answer: 'You  will get the result & detailed performance analysis report within 72 hours of the test conduction. For AIOOT, there will be a separate Result Timeline.'
        },
        {
            question: 'How can students check their test performance?',
            answer: 'Students can check their test results in the “Test” tab after they login to the SHRI JI website.'
        }
    ]

    return (
        <>
            <div className={`w-full flex bg-[#eff2f5] items-center justify-center px-[20px] flex-col`}>
                <div className={`w-full flex items-center justify-start py-[10px]`}>
                    <h2 className={`text-[18px] font-[600]`}>FAQs for NEET(UG) Major Online Test Series</h2>
                </div>
                <div className={`w-full h-auto flex items-center justify-start py-[15px] flex-col`}>
                    {
                        que.map((item, index) => {
                            return (
                                <>
                                    <div className={`w-full h-auto py-[12px] flex items-start flex-col justify-center`}>
                                        <h3 className={`font-[500]`}>
                                            <span className={` text-[18px]  font-[400]`}>{index + 1}.</span>
                                            {item.question}</h3>
                                        <p>{item.answer}</p>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default RefundPolicy