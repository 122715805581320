import { useState } from 'react';
import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Homepage from './components/Homepage';
import MobilNav from './components/MobilNav';
import Navbar from './components/Navbar';
import { Route, Routes } from 'react-router-dom';
import Neet12th from './components/Neet12th';
import MajorTestSeries from './components/MajorTestSeries';
import Footer from './components/Footer/Footer';
import RefundPolicy from './components/RefundAndPolicy/RefundPolicy';

function App() {
  const [openMNav, setOpenMNav] = useState(false);
  const toggle = () => {
    setOpenMNav(!openMNav)
  }
  return (
    <>

      {/* navbar */}
      <Navbar openMNav={openMNav} toggle={toggle} />
      <MobilNav openMNav={openMNav} toggle={toggle} />
      {/* navbar */}

      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/About' element={<About />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/freqans' Component={RefundPolicy} />
        <Route path='/neetfor12th' Component={Neet12th} />
        <Route path='/major-online-test' Component={MajorTestSeries} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
