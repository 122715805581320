import React from 'react'
const About = () => {
  return (
    <>

      <div className='About-section'>
        {/* <!-- Left Section --> */}
        <div className='About-left-section'>
          <h1>About Us</h1>

          <div className="image-box">
            <h2>About thinkIIT-thinkNEET Shri Ji Institute AITS: Revolutionizing NEET & IIT-JEE Preparation</h2>
            <img src="https://aits.thinkiit.in/wp-content/uploads/2023/06/Prepare-yourself-Good-luck-all-300x300.png" alt="About Us Image" />
          </div>

          <h2>Welcome to the Future of NEET & IIT-JEE Preparation</h2>
          <p>With the competitiveness of NEET and IIT-JEE exams on the rise, the need for effective and personalized preparation strategies is more critical than ever. That’s where the All India Test Series (AITS) by thinkIIT-thinkNEET Shri Ji Institute comes into play.</p>

          <h2>“Elevate Your Preparation, Achieve Success”</h2>
          <a href="#">Begin Your Journey</a>

          <h2>Unleash Your Potential</h2>
          <p>Our All India Test Series is the ultimate tool to fine-tune your NEET and IIT-JEE preparation. Experience a systematic, detailed and comprehensive study process like never before.</p>

          <h2>About: The Landscape of NEET & IIT-JEE Preparation</h2>
          <p>Over the years, the way students prepare for these crucial exams has transformed dramatically.</p>
          <p>Traditional Methods of Preparation</p>
          <p>In the past, aspirants relied on extensive self-study, coaching centers, or private tutors to prepare for these exams. They followed rigid schedules, studying multiple hours a day, sometimes at the expense of their health and well-being.</p>
          <p>Challenges with Conventional Approaches</p>
          <p>While these traditional methods have produced successful candidates, they often lack personalization and flexibility. Plus, without a systematic approach and in-depth performance analysis, students may find it challenging to identify their strengths and weaknesses effectively.</p>

          <h2>About: thinkIIT-thinkNEET Shri Ji Institute AITS</h2>
          <p>So, what if there was a way to make your NEET or IIT-JEE preparation more efficient, effective, and personalized?</p>

          <h3>What is AITS?</h3>
          <p>The All India Test Series, or AITS, is a comprehensive, technology-driven preparation tool designed to revolutionize the way you prepare for your NEET or IIT-JEE examinations.</p>

          <h3>How AITS Transforms Your Preparation</h3>
          <p>With AITS, you can participate in tests that mirror the actual examination environment, enabling you to adapt to the real exam’s timing and pressure.</p>
          <p>On top of this, thinkIIT’s competence in augmentation keeps it ahead of the herd. “We try to bring in something new each time we tweak our content”, declares Jnanesh. The use of state-of-the-art technology has led to critical developments in their products and services every year.</p>
          <p>“We are a team of self-motivated individuals who are working on a common goal to create a niche in the EdTech segment”, says Manish. Along with the dynamic duo, thinkIIT team consists of self-empowered specialists, mainly from the IT-Sphere. Despite being a small team, the workforce is the biggest asset for the brand. From product development to streamlining their operations or performing R&D, thinkIIT’s team has addressed everything. To reward their efforts, the brand maintains a very flexible working hour and work from home as well. The agile framework fosters a work-life balance for every member on board.</p>

          <h2>About thinkIIT Formation</h2>
          <p>thinkIIT was formed to be a catalyst in every aspirant’s career; this core motivation has helped it enlighten the career of around two lakh aspirants. thinkIIT is currently operating in – 160+ cities across – 25 states, in India. Along with online teaching, the Company has comprehensive test papers, subject companions, etc. These associated study materials have significant popularity in e-Commerce platforms like Amazon.</p>
          <p>In the road ahead, thinkIIT’s focus resides on further technological augmentations. Bleeding edge technologies, such as AR & VR, which are already redefining EdTech space, are the next landmarks of thinkIIT.</p>
        </div>

        {/* <!-- Right Section --> */}
        <div className='About-right-section'>
          <div class="About-search-container">
            <div class="search-wrapper">
              <input type="text" class="search-input" placeholder="Search..." />
              <span class="Contact-icon">🔍</span>
            </div>
          </div>
          <h2>Recent Posts</h2>
          <div class="About-links">
            <a href="#">thinkIIT-thinkNEET Shri Ji Institute All</a>
            <a href="#">India Test Series: The Ultimate</a>
            <a href="#">Guide to Cracking NEET 2024</a>
          </div>

          <h2>Recent Comments</h2>
          <div class="About-links">
            <a href="#">SHRIKANT on thinkIIT-thinkNEET Shri Ji Institute All India Test Series: The Ultimate Guide to Cracking NEET 2024</a>
            <a href="#">Jitendra on thinkIIT-thinkNEET Shri Ji Institute All India Test Series: The Ultimate Guide to Cracking NEET 2024</a>
          </div>

          <div class="About-search-container">
            <div class="search-wrapper">
              <input type="text" class="search-input" placeholder="Search..." />
              <span class="Contact-icon">🔍</span>
            </div>
          </div>


          <h2>Recent Posts</h2>
          <div class="About-links">
            <a href="#">thinkIIT-thinkNEET Shri Ji Institute All</a>
            <a href="#">India Test Series: The Ultimate</a>
            <a href="#">Guide to Cracking NEET 2024</a>
          </div>

          <h2>Recent Comments</h2>
          <div class="About-links">
            <a href="#">SHRIKANT on thinkIIT-thinkNEET Shri Ji Institute All India Test Series: The Ultimate Guide to Cracking NEET 2024</a>
            <a href="#">Jitendra on thinkIIT-thinkNEET Shri Ji Institute All India Test Series: The Ultimate Guide to Cracking NEET 2024</a>
          </div>
        </div>
      </div>

      {/* home-pannel12 */}

      <div className='home-pannel12-container'>
        <button className='home-pannel12-view-all-button'>View All Posts</button>
        <div className='home-pannel12-content-container'>
          <div className='home-pannel12-left-section'>
            <div className='home-pannel12-heading'>
              <h1>Shri Ji Institute AITS</h1>
            </div>
            <p>🏆 A Path to Excellence: Shri Ji Institute AITS (All India Test Series) is not just about preparing for exams; it’s about preparing for success. Join the ranks of high achievers who have used AITS as a stepping stone to their dreams.</p>
          </div>
          <div className='home-pannel12-right-section'>
            <h2>Programs</h2>
            <ul className='home-pannel12-links-list'>
              <li><a href='#'>Shri Ji Institute AITS For NEET</a></li>
              <li><a href='#'>Shri Ji Institute AITS For JEE</a></li>
            </ul>
          </div>
        </div>
      </div>

      {/* home-pannel13 */}



    </>
  )
}

export default About
