import { Icon } from '@iconify/react/dist/iconify.js'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const TestSeriesBox = ({ data }) => {
    const navigate = useNavigate()
    // const data = {
    //     testseries: "ONLINE TEST SERIES",
    //     div1mainheading: "Major Test Series",
    //     div1subhead: "All india Test Series",
    //     div2data: [
    //         "14+ Full Syllabus Tests",
    //         "Detailed Solutions to All Question",
    //         "Exclusive Shreeji Question Bank",
    //     ],
    //     div3falseprice: "5999",
    //     div3trueprice: "1499",
    //     knowmoreDirect: ""
    // }
    return (
        <>
            <li onClick={() => navigate(data.knowmoreDirect)} className={`li-for-online-box`}>
                {/* div1 */}
                <div className={`div1`}>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><path stroke="#D8DBDF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M5.776 3.967h2.667c1.333 0 1.333-.667 1.333-1.334C9.776 1.3 9.11 1.3 8.443 1.3H5.776c-.666 0-1.333 0-1.333 1.333 0 1.334.667 1.334 1.333 1.334z"></path><path stroke="#D8DBDF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M9.777 2.647c1.619.087 2.704.712 3.005 1.786M4.443 2.647c-2.22.12-3.333.946-3.333 3.986v4c0 2.667.667 4 4 4h4c3.333 0 4-1.333 4-4v-2.43"></path><path stroke="#D8DBDF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M12.008 4.88l-4.71 3.787c-.15.124-.31.382-.355.575L6.625 10.6c-.117.491.204.87.707.845l1.392-.075c.194-.01.475-.123.629-.248l4.71-3.786c.676-.566 1.032-1.261.2-2.255-.832-.994-1.58-.766-2.255-.2zM10.825 6.065a3.016 3.016 0 001.893 2.261"></path></svg>
                        <p>{data.testseries}</p>
                    </span>
                    <div>
                        <h3>{data.div1mainheading}</h3>
                        <p>{data.div1subhead}</p>
                    </div>
                </div>

                {/* div2 */}
                <ul className={`div2`}>

                    {
                        data.div2data.map((item) => {
                            return (
                                <>
                                    <li>
                                        <Icon icon="hugeicons:tick-02" style={{ color: "black" }} />
                                        <p>{item}</p>
                                    </li>
                                </>
                            )
                        })
                    }
                </ul>

                {/* div3 */}
                <div className={`div3`}>
                    <div className={`first-div-div3`}>
                        <span>
                            &#8377;
                            {data.div3falseprice}</span>
                    </div>
                    <div className={`second-div-div3`}>
                        <span>
                            &#8377;{data.div3trueprice}
                        </span>
                        <button >

                            <p>Know more</p>
                            <span>
                                <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 15L12.5 10L7.5 5" stroke="#3F8DFD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                            </span>
                        </button>
                    </div>
                </div>
            </li>

        </>
    )
}

export default TestSeriesBox