import React, { useEffect, useState } from 'react'
import token from '../Config/Token'
import Backend_Url from '../Config/BackendUrl'
import axios from 'axios'
import { Icon } from '@iconify/react/dist/iconify.js'
import { useNavigate } from 'react-router-dom'
import { FaQuestion } from 'react-icons/fa'

const MajorTestSeries = () => {
    const [data, setdata] = useState([])
    const [button, setbutton] = useState([])
    // const navigate = useNavigate()
    const fetchdata = async () => {
        try {
            await axios.get(`${Backend_Url}/Footer/getAll`,
                {
                    headers: {
                        'authorization': 'Bearer ' + token
                    }
                }
            )
                .then((res) => setdata(res.data.data))
        } catch (error) {
            console.log(error)
            return window.alert('Error while fetching data')
        }
    }

    // "src/assert/images2.png"

    // --------------------------fetchbutton--------------------------------------------
    const fetchbutton = async () => {
        try {
            await fetch(`${Backend_Url}/academicmaster/getAll`,
                {
                    method: 'GET',
                    headers: {
                        'authorization': 'Bearer ' + token
                    }
                }
            )
                .then((res) => res.json())
                .then((res) => setbutton(res.data))
        } catch (error) {
            console.log(error)
            window.alert('could not fetch all data')
        }
    }

    useEffect(() => {
        fetchdata()
        fetchbutton()
    }, []);

    console.log(button[0]);
    const navigate = useNavigate()
    return (
        <>
            <div className={`main-majortest`}>
                {/* left */}
                <div className={`left-majortest`}>
                    {
                        data.map((item) => {
                            return (
                                <>
                                    <div dangerouslySetInnerHTML={{ __html: item.TextContent }} />
                                </>
                            )
                        })
                    }

                    {/* --------downloadbtn-------------------- */}
                    <button onClick={() => navigate(button[0].EventVideoTitle)} className='download-syllabus'>
                        <p>{button[0]?.EventTitle}</p>
                        <Icon icon="material-symbols-light:download" style={{ width: "30px", height: "30px", color: "black" }} />
                    </button>


                    {/* ---------------- info-------------------------*/}

                    <div className={`policy-btn`}>
                        <button onClick={() => navigate("/freqans")}>
                            <p>
                                Frequently asked questions
                            </p>
                            <span>
                                <FaQuestion style={{ marginLeft: '10px' }} />
                                {/* <Icon icon="solar:forward-bold" style={{ color: "black" }} /> */}
                            </span>
                        </button>
                        <button>
                            <p>
                                Refund policy
                            </p>
                            <span>
                                <Icon icon="solar:forward-bold" style={{ color: "black" }} />
                            </span>
                        </button>
                    </div>
                </div>


                {/* right */}
                <div className={`right-majortest`}>
                    <div className={`annul-details`}>
                        <div className='div1-anual-details'>
                            <p>Annual Fee</p>
                            <span>
                                <p className={`sub-price`}>&#8377;5999</p>
                                <p>&#8377;4999</p>
                            </span>
                        </div>

                        <div className={`language-select`}>
                            {/* top */}
                            <div className={'top'}>
                                <h3>Select Programming Language</h3>
                                <div>
                                    <input type="radio" id="english" name="language" value="english" />
                                    <label for="python">English</label>
                                    <span>EN</span>
                                </div>
                            </div>

                            {/* bottom */}
                            <div className='bottom'>
                                <h3>Select starting date</h3>
                                <div>
                                    <input type='radio' name='phasestarting' />
                                    <p>Phase 1 Starting 24 Nov 2024</p>
                                </div>
                                <p>Missed test will be available for re-attempt</p>
                            </div>
                        </div>


                        {/* buy now btn */}
                        <button className={`buy-now`}>
                            Buy Now
                        </button>


                    </div>
                </div>
            </div>
        </>
    )
}

export default MajorTestSeries