import React from "react";
import logo from "../../assert/logo.jpeg";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
// import { FaEnvelope, FaPhone } from "react-icons/fa";

const Footer = () => {
    const socialmedia = [
        {
            name: "faceboook",
            link: "",
            icon: <FaFacebook />
        },
        {
            name: "Instagram",
            link: "",
            icon: <FaInstagram />
        },
        {
            name: "Twitter",
            link: "",
            icon: <RiTwitterXFill />
        }
    ]


    return (
        <footer className="bg-footerbg text-[#ffd79b] py-8 pb-0 bg-[#504e4e]">
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 px-4">
                <div className="col-span-1">
                    <div className="flex justify-center md:justify-start">
                        <img src={logo} alt="KANHAIYA CATERERS" className="mb-4 h-[5rem]" />
                    </div>
                    <p className="text-[15px] text-navcolor p-2 font-great-vibes text-center md:text-left">
                        Join the Shri Ji Institute All India Test Series, the ultimate preparation platform for NEET, JEE, MHT-CET, MHT_NDA aspirants.
                    </p>
                </div>
                <div>
                    <h4 className="text-[#ffd79b] text-2xl md:text-3xl border-b border-b-[#f29d17] md:pb-[4px] font-semibold  md:mb-6 text-left md:text-left">
                        Courses
                    </h4>
                    <ul className="leading-8 text-lg text-left md:text-left">
                        <li>
                            <Link className="hover:underline hover:text-navcolor transform transition-tranform duration-300 ease-in-out ">
                                NEET
                            </Link>
                        </li>
                        <li>
                            <Link className="hover:underline transform transition-tranform duration-300 ease-in-out hover:text-navcolor">
                                JEE
                            </Link>
                        </li>
                        <li>
                            <Link className="hover:underline transform transition-tranform duration-300 ease-in-out hover:text-navcolor">
                                MHT-C
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/menu" className="hover:underline transform transition-tranform duration-300 ease-in-out hover:text-navcolor">
                                Menu
                            </Link>
                        </li> */}
                        <li>
                            <Link className="hover:underline transform transition-tranform duration-300 ease-in-out hover:text-navcolor">
                                MHT-N
                            </Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <h4 className="text-[#ffd79b]  text-2xl md:text-3xl border-b border-b-[#f29d17] pb-2 md:pb-[5px] font-semibold mb-4 md:mb-6 text-left md:text-left">
                        Test Series
                    </h4>
                    <ul className="leading-8 text-lg text-left md:text-left">
                        <li>
                            <div className="cursor-default">
                                <p className="flex justify-start md:justify-start">
                                    <span className="text-navcolor mr-2">Class 11</span>

                                </p>
                                <p className="flex justify-star md:justify-start">
                                    <span className="text-navcolor mr-2">Class 12</span>
                                </p>
                                <p className="flex justify-star md:justify-start">
                                    <span className="text-navcolor mr-2">Class 12 Pass</span>
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div>
                    <h4 className="text-[#ffd79b]  text-2xl md:text-3xl border-b border-b-[#f29d17] pb-2 md:pb-[5px] font-semibold mb-4 md:mb-6 text-left md:text-left">
                        Follow Us
                    </h4>
                    <address className="not-italic text-left md:text-left">

                        <div className={`w-full h-auto`}>
                            <p>
                                {/* <strong>Kota Office:</strong>
                                <p className={`text-[15px]`}>Near Ahimsa Circle, Shrinath Puram, Sector-A Kota, Rajasthan<br />
                                </p> */}
                                <strong>Bangalore Office:</strong>
                                <p className={`text-[15px]`}>
                                    602 C, sushma Grande, Ambala Chandigarh Highway.
                                </p>
                                <p className={`text-[15px]`}>
                                    <strong>Mobile No:- </strong>
                                    8295091659
                                </p>
                            </p>
                        </div>
                        <div className={`w-full h-auto flex items-center justify-start gap-1`}>
                            {
                                socialmedia.map((item, index) => {
                                    return (
                                        <>
                                            <Link to={item.link} className={`text-xl cursor-pointer`}>
                                                {item.icon}
                                            </Link>
                                        </>
                                    )
                                })
                            }
                        </div>
                        {/* <a href="tel:+917999494952" className="flex items-center  md:justify-start text-navcolor hover:underline">
              <FaPhone className="mr-2" style={{ transform: 'rotate(90deg)' }} />
              +91 79994 94962
            </a> */}
                    </address>
                </div>
            </div >

            <div className={`w-full h-auto py-[10px] flex items-center justify-center `} style={{ backgroundColor: "rgb(89 91 94)" }}>
                <h2 className={`f_bottom_text m-0 p-0 text-[15px] sm:text-[12px] font-[400]`}>Copyright 2024 ©All rights reserved | Developed By TechCherry</h2>
            </div>
        </footer >
    );
};

export default Footer;
