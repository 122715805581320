import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./MobileNav.css"
import token from '../Config/Token';
import Backend_Url from '../Config/BackendUrl';

const MobilNav = ({ openMNav, toggle }) => {
  const [mainname, setmainmenu] = useState([]);
  const [submenu, setsubmenu] = useState([])
  const navigate = useNavigate()
  function toggleMenu() {
    const navLinks = document.querySelector('.nav-links');
    navLinks.classList.toggle('active');
  }

  const fetdata = async () => {
    debugger
    console.log(Backend_Url);
    try {
      await fetch(`${Backend_Url}MenuMaster/getall`,
        {
          method: 'GET',
          headers: {
            'authorization': 'Bearer ' + token
          }
        }
      )
        .then((res) => res.json())
        .then((data) => {
          const Data = data.data
          Data.map((item) => {
            if (item.GruopName == "null") {
              setmainmenu((prev) => {
                // const existing = new Set(prev)
                // const newcontact = Data.filter((Datavalue) => !existing.has(Datavalue))
                // console.log("existing", existing);
                // console.log("new", newcontact);
                // return [...existing, ...newcontact]

                const existingIds = new Set(prev.map(menuItem => menuItem.Id)); // Create a Set of existing IDs
                if (!existingIds.has(item.Id)) { // Check if the item ID already exists
                  existingIds.add(item.Id); // Add the new item's ID
                  return [...prev, item]; // Return the new array with the item added
                }
                return prev;
              });
              // setmainmenu((prev) => !prev.includes(item) ? [...prev, item] : [...prev])
            } else {
              setsubmenu((prev) => {
                const existingIds = new Set(prev.map(menuItem => menuItem.Id)); // Create a Set of existing IDs
                if (!existingIds.has(item.Id)) { // Check if the item ID already exists
                  existingIds.add(item.Id); // Add the new item's ID
                  return [...prev, item]; // Return the new array with the item added
                }
                return prev;
              });
              // setsubmenu((prev) => !prev.includes(item) ? [...prev, item] : [...prev])
            }
          })
          console.log(data)
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetdata()
  }, []);

  useEffect(() => {
    console.log(submenu);
    console.log(mainname);

  }, [submenu, mainname]);

  const [showsubmenu, setshowsubmenu] = useState([])
  // const [alldata, setalldata] = useState([]);
  // console.log(alldata);
  // // const [logo , setlogo] = useState(null);

  // useEffect(() => {fetdata();fetlogodata()}, [])

  // const fetdata = async () => {

  //   try {
  //     await fetch(`${Backend_Url}MenuMaster/getall`,
  //       {
  //         method: 'GET',
  //         headers: {
  //           'authorization': 'Bearer ' + token
  //         }
  //       }
  //     )
  //       .then((res) => res.json())
  //       .then((res) => setalldata(res.data))
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const[logo , setlogo] = useState(null)
  // console.log(logo) 
  // const fetlogodata = async () => {

  //   try {
  //     const response = await fetch(`${Backend_Url}GeneralSetting/get/all`,
  //       {
  //         method: 'GET',
  //         headers: {
  //           'authorization': 'Bearer ' + token,
  //         }
  //       }
  //     );
  //     const data = await response.json();

  //     console.log(data);
  //     setlogo(data)

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // useEffect(() => { fetlogodata();},[] )

  function handleenter(item) {
    !showsubmenu.includes(item.Category_sub) ? setshowsubmenu([item.Category_sub]) : setshowsubmenu([])
  }

  function handleleave(item) {
    setshowsubmenu([])
  }


  const subnavclick = (item) => {
    try {
      toggle()
      navigate(item.URL != 'null' ? item.URL : null)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    console.log(openMNav);
  }, [openMNav])
  return (
    <>
      {/* {openMNav && <nav class="m_navbar">
        <ul class="mnav_links pl-[20px]">
          <li className={`res-btn`}>
            <div class="hamburger" onClick={toggle}>
              &#10006;
            </div>
          </li>
          <li><Link className='li' onClick={toggle} to="/">Home</Link></li>
          <li><Link className='li' onClick={toggle}  >NEET</Link></li>
          <li><Link className='li' onClick={toggle} >JEE</Link></li>
          <li><Link className='li' onClick={toggle} >MHT-CET</Link></li>
          <li><Link className='li' onClick={toggle} >MHT-NDA</Link></li>
          <li><Link className='li' onClick={toggle} >BLOGS</Link></li>
          <li><Link className='li' onClick={toggle} to="/ABOUT">ABOUT US</Link></li>
          <li><Link className='li' onClick={toggle} to="/CONTACT">CONTACT US</Link></li>
        </ul>
      </nav>} */}
      {openMNav && <div className="m_navbar">
        <ul class="mnav_links pl-[20px]">
          <li className={`res-btn`}>
            <div class="hamburger" onClick={toggle}>
              &#10006;
            </div>
          </li>
          {

            mainname.map((item) => {
              return (
                <>
                  <li className={` min-h-[30px] max-h-max flex items-start
                     justify-center flex-col `} onClick={() => handleenter(item)} style={{ height: 'auto', display: 'flex', justifyContent: 'center', position: 'relative' }}>
                    <Link className='li' onClick={item.Category_sub != 'NEET' ? toggle : null} to={item.URL != 'null' ? item.URL : null}>{item.Category_sub}</Link>
                    {
                      // ----------------------- dropdown -------------------------------------
                      showsubmenu.includes(item.Category_sub) && (
                        <ul className={`submenu`} style={{ height: 'auto', position: 'static' }}>
                          {submenu.filter((subitem) => item.Category_sub == subitem.GruopName).map((item) => {
                            return <li className={`text-black font-[600] border-b border-b-black`} onClick={() => subnavclick(item)}>{item.Category_sub}</li>
                          })}
                        </ul>
                      )
                    }

                  </li>
                </>
              )
            })
          }
          {/* <li><Link >NEET</Link></li>
                  <li><Link >JEE</Link></li>
                  <li><Link >MHT-CET</Link></li>
                  <li><Link >MHT-NDA</Link></li>
                  <li><Link >BLOGS</Link></li>
                  <li><Link to={"/About"}>ABOUT US</Link></li>
                  <li><Link to={"/Contact"}>CONTACT US</Link></li> */}
        </ul>
        <div onClick={toggle} className={`w-[60%] z-[20] h-screen !bg-transparent opacity-0 fixed`} />

      </div>

      }

    </>
  )
}

export default MobilNav
